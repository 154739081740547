import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { throwError, Observable, Observer, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as configs from '../../app/configurable.json';
import { Plugins } from '@capacitor/core';
import { NavController } from '@ionic/angular';
const { Storage, Geolocation, Modals } = Plugins;
import { DomSanitizer } from '@angular/platform-browser';
import { AlertasService } from './alertas.service.js';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from '../../environments/environment';
import { CryptoService } from './crypto.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  google_captcha: any = environment.gcaptcha;
  params: any = environment.api;
  datos: any;
  patente: any;
  telefono: any;
  email: any;
  singlex: Subscription;
  data: HttpParams = new HttpParams()
    .set('usuario', this._crypto.dec(this.params.uss_))
    .set('password', this._crypto.dec(this.params.pss_));
  apiUrl: string = environment.api.ull_;
  apiUrl_2: string = environment.api_2.url;
  apiUrlcom: string = environment.api.ull_com;
  constructor(
    private http: HttpClient,
    private nav: NavController,
    private sanitizer: DomSanitizer,
    private alerta: AlertasService,
    private device: DeviceDetectorService,
    private _crypto: CryptoService,
    private gc: ReCaptchaV3Service
  ) {

  }

  // importante, no cambiar
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json'
    })
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ocurrióun error :', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError('Algo malo sucedió, intentalo otra vez.');
  };

  getCaptchaToken(action: string) {
    return new Promise((res, rej) => {
      if (this.singlex) this.singlex.unsubscribe;
      this.singlex = this.gc.execute('solicitar').subscribe(
        (token) => { return res(token) ; },
        (error) => { return rej('Error Interno, Intentelo más tarde.'); }
      );
    })

  }

  solicitarAsistencia(parametros: any = {}): Observable<any> {
    return Observable.create(async (observer: Observer<any>) => {
      console.warn("Parametros asistencia", parametros);
      
      let gcaptcha:any = await this.getCaptchaToken('solicitar');

      var vehiculo = JSON.parse(localStorage.getItem("_cap_vehiculo_seleccionado"));

      this.getPatente().then(e => {
        var datos: HttpParams = new HttpParams()      
          .set('usuario', this._crypto.dec(this.params.uss_))
          .set('password', this._crypto.dec(this.params.pss_))
          .set('patente', this.patente.Patente)
          //.set('color', vehiculo.Color)
          .set('referencia', vehiculo.Referencia)
          .set('mail', this.email)
          .set('device_device', this.device.device)
          .set('device_browser', this.device.browser)
          .set('device_sistema', this.device.os)
          .set('g_c', gcaptcha.toString());
        if (!parametros.telefono) {
          parametros.telefono = '+569' + this.telefono;
        } else {
          parametros.telefono = '+569' + parametros.telefono;
        }
        configs.parametros.color = vehiculo.Color
      
        let datos_ = datos.toString();
        var param = this.mergeDict(
          configs.parametros,
          parametros
        );
        datos_ += "&" + this.serializar(param);


        console.log(datos_);
        this.http.post(
          this.apiUrl + configs.api.solicitarAsistencia, 
          datos_, 
          this.httpOptions
        ).pipe(catchError(this.handleError)).subscribe(
          data => {
            console.log("mensajeeee ");
            console.log(data);
            if (data['error_code'] == 401) {
              this.flushAndLogin();
            }
            observer.next(data);
            observer.complete();
            this.setAsistenciaEstado(false);
          }, err => {
            console.log("mensajeeee error!!!");
            console.log(err);
            observer.error(err);
          }
        );
      });
    });
  }

  //Silicitar Asistencia con el middleware 2.0
  solicitarAsistencia2(parametros: any = {}): Observable<any> {
    return Observable.create(async (observer: Observer<any>) => {
      console.warn("Parametros asistencia", parametros);
      
      let gcaptcha:any = await this.getCaptchaToken('solicitar');

      var vehiculo = JSON.parse(localStorage.getItem("_cap_vehiculo_seleccionado"));

      this.getPatente().then(e => {
        var datos: HttpParams = new HttpParams()
          .set('company_id', vehiculo.CompanyId)
          .set('usuario', this._crypto.dec(this.params.uss_))
          .set('password', this._crypto.dec(this.params.pss_))
          .set('patente', this.patente.Patente)
          .set('referencia', vehiculo.Referencia)
          .set('mail', this.email)
          .set('device_device', this.device.device)
          .set('device_browser', this.device.browser)
          .set('device_sistema', this.device.os)
          .set('g_c', gcaptcha.toString())
          .set('token_ias', localStorage.getItem('token_ias'));
        if (!parametros.telefono) {
          parametros.telefono = '+569' + this.telefono;
        } else {
          parametros.telefono = '+569' + parametros.telefono;
        }
        configs.parametros.color = vehiculo.Color;
        configs.parametros.marca = vehiculo.Marca;
        configs.parametros.modelo = vehiculo.Modelo;
        configs.parametros.anio = vehiculo.Anio;
        configs.parametros.contract_id = vehiculo.ContractId;
        configs.parametros.client_id = vehiculo.ClientId;
        configs.parametros.nombres = vehiculo.ClientName;
        configs.parametros.apellidos = vehiculo.ClientSurname;
      
        let datos_ = datos.toString();
        var param = this.mergeDict(
          configs.parametros,
          parametros
        );
        datos_ += "&" + this.serializar(param);

        console.log(datos_);
        this.http.post(
          this.apiUrl_2 + configs.api_2.solicitarServicio, 
          datos_, 
          this.httpOptions
        ).pipe(catchError(this.handleError)).subscribe(
          data => {
            console.log(data);
            if (data['error'] == 2) {
              this.flushAndLogin();
            }
            observer.next(data);
            observer.complete();
            this.setAsistenciaEstado(false);
          }, err => {
            console.log(err);
            observer.error(err);
          }
        );
      });
    });
  }

  FormatDireccion(direccion) {
    var dir = direccion.split(",");

    if(dir.length > 3){
      dir.pop();
    }
    dir.pop();

    var direcc = "";
    if(dir.length > 2){
      direcc += dir[0] + "," +  dir[1] + "," + dir[2];
    }else{
      direcc += dir[0] + "," +  dir[1];
    }
    return direcc;
  }

  getAsistenciasDisponibles(patente: string) {
    return Observable.create((observer: Observer<any>) => {
      this.obtenerRut().then(rut => {
        this.getPatente().then(r => {
          var datos = this.data
            .set('patente', patente)
            .set('rut', rut.rut)
            .set('dv', rut.dv);
          return this.http.post(this.apiUrl + 'getMenuAsistencias.php', datos.toString(), this.httpOptions)
            .subscribe(data => {
              observer.next(data);
              return observer.complete();
            }, err => {
              observer.error(err);
              return observer.complete();
            })
        })
      })
        .catch(err => console.error('Error al obtener Rut', err));
    })
  }

  getAsistenciasDisponibles2(patente: string) {
    return Observable.create((observer: Observer<any>) => {
      this.obtenerRut().then(rut => {
        this.getPatente().then(r => {
          var vehiculo = JSON.parse(localStorage.getItem("_cap_vehiculo_seleccionado"));
          var datos = this.data
            .set('company_id', vehiculo.CompanyId)
            .set('contract_id', vehiculo.ContractId)
            .set('token_ias', localStorage.getItem('token_ias'));
          return this.http.post(this.apiUrl_2 + configs.api_2.getPrestaciones, datos.toString(), this.httpOptions)
            .subscribe(data => {
              observer.next(data);
              return observer.complete();
            }, err => {
              observer.error(err);
              return observer.complete();
            })
        })
      })
        .catch(err => console.error('Error al obtener Rut', err));
    })
  }

  getComunas() {
    return Observable.create((observer: Observer<any>) => {
      this.obtenerRut().then(rut => {
        this.getPatente().then(r => {
          var datos = this.data
            .set('usuario', this._crypto.dec(this.params.uss_))
            .set('password', this._crypto.dec(this.params.pss_))
            .set('rut', rut.rut)
            .set('dv', rut.dv);
          return this.http.post(this.apiUrlcom + 'getComunas.php', datos.toString(), this.httpOptions)
            .subscribe(data => {
              observer.next(data);
              return observer.complete();
            }, err => {
              observer.error(err);
              return observer.complete();
            })
        })
      })
        .catch(err => console.error('Error al obtener Rut', err));
    })
  }

  getComunas2() {
    return Observable.create((observer: Observer<any>) => {
      var datos = this.data
        .set('usuario', this._crypto.dec(this.params.uss_))
        .set('password', this._crypto.dec(this.params.pss_));
        return this.http.post(this.apiUrl_2 + configs.api_2.getComunas, datos.toString(), this.httpOptions)
      .subscribe(data => {
        observer.next(data);
        return observer.complete();
      }, err => {
        observer.error(err);
        return observer.complete();
      })
    })
  }

  flushAndLogin() {
    localStorage.clear();
    this.nav.navigateRoot('/login');
  }

  /**obtener datos de seguimientos */
  getSeguimiento(patente: string, token: string = '') {
    return Observable.create((observer: Observer<any>) => {
      this.getPatente().then(async r => {
        var datos = this.data
          .set('patente', patente)
          .set('g_c', token);
        return this.http.post(this.apiUrl + configs.api.getSolicitudes, datos.toString(), this.httpOptions)
          .pipe(
            catchError(this.handleError)
          ).subscribe(data => {
            if (data['error_code'] == 401) {
              this.flushAndLogin();
            }
            observer.next(data);
            observer.complete();
          }, err => {
            observer.error(err);
            observer.complete();
          });
      });
    });
  }

  getSeguimiento2(patente: string, contract_id: string, client_id: string, token: string = '') {
    return Observable.create((observer: Observer<any>) => {
      this.obtenerRut().then(rut => {
        this.getPatente().then(async r => {

          var vehiculo = JSON.parse(localStorage.getItem("_cap_vehiculo_seleccionado"));

          var datos = this.data
            .set('company_id', vehiculo.CompanyId)
            .set('patente', patente)
            .set('rut', rut.rut + rut.dv)
            .set('contract_id', contract_id)
            .set('client_id', client_id)
            .set('g_c', token)
            .set('token_ias', localStorage.getItem('token_ias'));
          return this.http.post(this.apiUrl_2 + configs.api_2.getServicios, datos.toString(), this.httpOptions)
            .pipe(
              catchError(this.handleError)
            ).subscribe(data => {
              if (data['error'] == 2) {
                this.flushAndLogin();
              }
              observer.next(data);
              observer.complete();
            }, err => {
              observer.error(err);
              observer.complete();
            });
        });
      });
    });
  }

  // obtiene un login segun datos provenientes de 
  // login.page.ts - login.page.html
  getLogin(rut: string, dv: string, gc: string = '') {
    var datos = this.data
      .set('rut', rut)
      .set('dv', dv)
      .set('g_c', gc)
      .set('device_device', this.device.device)
      .set('device_browser', this.device.browser)
      .set('device_sistema', this.device.os);
    return this.http.post(
      this.apiUrl + configs.api.obtenerPatentes, 
      datos.toString(), 
      this.httpOptions
      ).pipe(
        catchError(this.handleError)
      )
  }

  //login middleware 2.0
  getLogin2(rut: string, gc: string = ''){
    var datos = this.data
    .set('rut', rut)
    .set('g_c', gc)
    .set('usuario', this._crypto.dec(this.params.uss_))
    .set('password', this._crypto.dec(this.params.pss_))
    .set('device_device', this.device.device)
    .set('device_browser', this.device.browser)
    .set('device_sistema', this.device.os);
  return this.http.post(
    this.apiUrl_2 + configs.api_2.login, 
    datos.toString(), 
    this.httpOptions
    ).pipe(
      catchError(this.handleError)
    )
  }

  // sección local storage 
  async guardarDato(key: string, dato: any) {
    await Storage.set({
      key: key,
      value: JSON.stringify(dato)
    });
  }

  /** obtiene unicamente la patente. */
  async getPatente_data() {
    return new Promise((res, rej) => {
      this.obtenerDato('vehiculo_seleccionado').then(r => {
        return res(r.Patente);
      }).catch(e => {
        return rej(e);
      })
    });
  }


  async getPatente_post() {
    const patente = await this.obtenerDato('vehiculo_seleccionado');
    return patente;
  }

  async getPatente() {
    await this.obtenerDato('vehiculo_seleccionado').then(d => {
      console.log("vehiculo: ", d);
    });
    const patente = await this.obtenerDato('vehiculo_seleccionado');
    const email = await this.obtenerDato('email');
    const telefono = await this.obtenerDato('telefono');
    this.patente = await patente;
    this.telefono = await telefono;
    this.email = await email;
  }

  async checkSession() {
    let rut = await this.obtenerDato('rut');
    return rut ? true : false;
  }

  async setAsistenciaEstado(mode: boolean) {
    Storage.set({ key: 'asistencia', value: JSON.stringify(mode) });
    return null
  }

  async getAsistenciaEstado() {
    return new Promise((res, rej) => {
      Storage.get({ key: 'asistencia' }).then(r => {
        res(JSON.parse(r.value));
      });
    });
  }

  async obtenerRut() {
    let rut = { rut: '', dv: '' };
    let pivot: any;
    pivot = await this.obtenerDato('rut');
    rut.rut = pivot.slice(0, -1);
    rut.dv = pivot.slice(-1);
    return rut;
  }

  async obtenerDato(key: string): Promise<any> {
    const item = await Storage.get({ key: key });
    return JSON.parse(item.value);
  }

  async limpiarDatos() {
    localStorage.clear();
  }


  logOut() {
    this.limpiarDatos();
    this.alerta.showToast("", "Su sesión fue cerrada exitosamente", 3, "", "dark");
    this.nav.navigateRoot(['/login']);
  }

  // get geolocacion 
  async getLocacion() {
    return await Geolocation.getCurrentPosition({
      enableHighAccuracy: true
    });
  }
  // mergeDicts
  mergeDict(a: any, b: any) {
    return Object.assign({}, a, b)
  }

  // serializar Diccionario a URLPARAM
  serializar(dict: any = []) {
    var str = [];
    for (let v in dict) { str.push(encodeURIComponent(v) + "=" + encodeURIComponent(dict[v])); };
    return str.join("&");
  }


  replaceRange(s: string, start: number, end: number, substitute: string) {
    return s.substring(0, start) + substitute + s.substring(end);
  }

  ofuscar_patente(patente: string): string {
    let patente_ofuscada: string;
    patente_ofuscada = this.replaceRange(patente, 1, 5, '****');
    return patente_ofuscada;
  }

  show_version() {
    let version = configs.version;
    if (environment.production == false) {
      version = "dev-"
    }
    version = version + configs.version;
    return version;
  }

  /** Obtener si la fecha es correcta a la revisión tecnica.
   */
  async consultar_fecha_patente(fecha: Date) {
    let mes = fecha.getMonth();
    let patente: any = await this.getPatente_data();
    let ultimo_digito = parseInt(patente.substring(6, 5));
    if (ultimo_digito == configs.calendario_patente[mes][1]) {
      // console.log("Mes correcto");
      return true;
    } else {
      // console.log("No es tu mes");
      return false;
    }
  }
}
