import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as configs from '../../app/configurable.json';
import { Plugins } from '@capacitor/core';
import { NavController } from '@ionic/angular';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ionic/angular";
import * as i3 from "@angular/platform-browser";
import * as i4 from "./alertas.service";
import * as i5 from "ngx-device-detector";
import * as i6 from "./crypto.service";
import * as i7 from "ng-recaptcha";
const { Storage, Geolocation, Modals } = Plugins;
import { DomSanitizer } from '@angular/platform-browser';
import { AlertasService } from './alertas.service.js';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from '../../environments/environment';
import { CryptoService } from './crypto.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
export class ApiService {
    constructor(http, nav, sanitizer, alerta, device, _crypto, gc) {
        this.http = http;
        this.nav = nav;
        this.sanitizer = sanitizer;
        this.alerta = alerta;
        this.device = device;
        this._crypto = _crypto;
        this.gc = gc;
        this.google_captcha = environment.gcaptcha;
        this.params = environment.api;
        this.data = new HttpParams()
            .set('usuario', this._crypto.dec(this.params.uss_))
            .set('password', this._crypto.dec(this.params.pss_));
        this.apiUrl = environment.api.ull_;
        this.apiUrl_2 = environment.api_2.url;
        this.apiUrlcom = environment.api.ull_com;
        // importante, no cambiar
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json'
            })
        };
    }
    handleError(error) {
        if (error.error instanceof ErrorEvent) {
            console.error('Ocurrióun error :', error.error.message);
        }
        else {
            console.error(`Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        return throwError('Algo malo sucedió, intentalo otra vez.');
    }
    ;
    getCaptchaToken(action) {
        return new Promise((res, rej) => {
            if (this.singlex)
                this.singlex.unsubscribe;
            this.singlex = this.gc.execute('solicitar').subscribe((token) => { return res(token); }, (error) => { return rej('Error Interno, Intentelo más tarde.'); });
        });
    }
    solicitarAsistencia(parametros = {}) {
        return Observable.create((observer) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.warn("Parametros asistencia", parametros);
            let gcaptcha = yield this.getCaptchaToken('solicitar');
            var vehiculo = JSON.parse(localStorage.getItem("_cap_vehiculo_seleccionado"));
            this.getPatente().then(e => {
                var datos = new HttpParams()
                    .set('usuario', this._crypto.dec(this.params.uss_))
                    .set('password', this._crypto.dec(this.params.pss_))
                    .set('patente', this.patente.Patente)
                    //.set('color', vehiculo.Color)
                    .set('referencia', vehiculo.Referencia)
                    .set('mail', this.email)
                    .set('device_device', this.device.device)
                    .set('device_browser', this.device.browser)
                    .set('device_sistema', this.device.os)
                    .set('g_c', gcaptcha.toString());
                if (!parametros.telefono) {
                    parametros.telefono = '+569' + this.telefono;
                }
                else {
                    parametros.telefono = '+569' + parametros.telefono;
                }
                configs.parametros.color = vehiculo.Color;
                let datos_ = datos.toString();
                var param = this.mergeDict(configs.parametros, parametros);
                datos_ += "&" + this.serializar(param);
                console.log(datos_);
                this.http.post(this.apiUrl + configs.api.solicitarAsistencia, datos_, this.httpOptions).pipe(catchError(this.handleError)).subscribe(data => {
                    console.log("mensajeeee ");
                    console.log(data);
                    if (data['error_code'] == 401) {
                        this.flushAndLogin();
                    }
                    observer.next(data);
                    observer.complete();
                    this.setAsistenciaEstado(false);
                }, err => {
                    console.log("mensajeeee error!!!");
                    console.log(err);
                    observer.error(err);
                });
            });
        }));
    }
    //Silicitar Asistencia con el middleware 2.0
    solicitarAsistencia2(parametros = {}) {
        return Observable.create((observer) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.warn("Parametros asistencia", parametros);
            let gcaptcha = yield this.getCaptchaToken('solicitar');
            var vehiculo = JSON.parse(localStorage.getItem("_cap_vehiculo_seleccionado"));
            this.getPatente().then(e => {
                var datos = new HttpParams()
                    .set('company_id', vehiculo.CompanyId)
                    .set('usuario', this._crypto.dec(this.params.uss_))
                    .set('password', this._crypto.dec(this.params.pss_))
                    .set('patente', this.patente.Patente)
                    .set('referencia', vehiculo.Referencia)
                    .set('mail', this.email)
                    .set('device_device', this.device.device)
                    .set('device_browser', this.device.browser)
                    .set('device_sistema', this.device.os)
                    .set('g_c', gcaptcha.toString())
                    .set('token_ias', localStorage.getItem('token_ias'));
                if (!parametros.telefono) {
                    parametros.telefono = '+569' + this.telefono;
                }
                else {
                    parametros.telefono = '+569' + parametros.telefono;
                }
                configs.parametros.color = vehiculo.Color;
                configs.parametros.marca = vehiculo.Marca;
                configs.parametros.modelo = vehiculo.Modelo;
                configs.parametros.anio = vehiculo.Anio;
                configs.parametros.contract_id = vehiculo.ContractId;
                configs.parametros.client_id = vehiculo.ClientId;
                configs.parametros.nombres = vehiculo.ClientName;
                configs.parametros.apellidos = vehiculo.ClientSurname;
                let datos_ = datos.toString();
                var param = this.mergeDict(configs.parametros, parametros);
                datos_ += "&" + this.serializar(param);
                console.log(datos_);
                this.http.post(this.apiUrl_2 + configs.api_2.solicitarServicio, datos_, this.httpOptions).pipe(catchError(this.handleError)).subscribe(data => {
                    console.log(data);
                    if (data['error'] == 2) {
                        this.flushAndLogin();
                    }
                    observer.next(data);
                    observer.complete();
                    this.setAsistenciaEstado(false);
                }, err => {
                    console.log(err);
                    observer.error(err);
                });
            });
        }));
    }
    FormatDireccion(direccion) {
        var dir = direccion.split(",");
        if (dir.length > 3) {
            dir.pop();
        }
        dir.pop();
        var direcc = "";
        if (dir.length > 2) {
            direcc += dir[0] + "," + dir[1] + "," + dir[2];
        }
        else {
            direcc += dir[0] + "," + dir[1];
        }
        return direcc;
    }
    getAsistenciasDisponibles(patente) {
        return Observable.create((observer) => {
            this.obtenerRut().then(rut => {
                this.getPatente().then(r => {
                    var datos = this.data
                        .set('patente', patente)
                        .set('rut', rut.rut)
                        .set('dv', rut.dv);
                    return this.http.post(this.apiUrl + 'getMenuAsistencias.php', datos.toString(), this.httpOptions)
                        .subscribe(data => {
                        observer.next(data);
                        return observer.complete();
                    }, err => {
                        observer.error(err);
                        return observer.complete();
                    });
                });
            })
                .catch(err => console.error('Error al obtener Rut', err));
        });
    }
    getAsistenciasDisponibles2(patente) {
        return Observable.create((observer) => {
            this.obtenerRut().then(rut => {
                this.getPatente().then(r => {
                    var vehiculo = JSON.parse(localStorage.getItem("_cap_vehiculo_seleccionado"));
                    var datos = this.data
                        .set('company_id', vehiculo.CompanyId)
                        .set('contract_id', vehiculo.ContractId)
                        .set('token_ias', localStorage.getItem('token_ias'));
                    return this.http.post(this.apiUrl_2 + configs.api_2.getPrestaciones, datos.toString(), this.httpOptions)
                        .subscribe(data => {
                        observer.next(data);
                        return observer.complete();
                    }, err => {
                        observer.error(err);
                        return observer.complete();
                    });
                });
            })
                .catch(err => console.error('Error al obtener Rut', err));
        });
    }
    getComunas() {
        return Observable.create((observer) => {
            this.obtenerRut().then(rut => {
                this.getPatente().then(r => {
                    var datos = this.data
                        .set('usuario', this._crypto.dec(this.params.uss_))
                        .set('password', this._crypto.dec(this.params.pss_))
                        .set('rut', rut.rut)
                        .set('dv', rut.dv);
                    return this.http.post(this.apiUrlcom + 'getComunas.php', datos.toString(), this.httpOptions)
                        .subscribe(data => {
                        observer.next(data);
                        return observer.complete();
                    }, err => {
                        observer.error(err);
                        return observer.complete();
                    });
                });
            })
                .catch(err => console.error('Error al obtener Rut', err));
        });
    }
    getComunas2() {
        return Observable.create((observer) => {
            var datos = this.data
                .set('usuario', this._crypto.dec(this.params.uss_))
                .set('password', this._crypto.dec(this.params.pss_));
            return this.http.post(this.apiUrl_2 + configs.api_2.getComunas, datos.toString(), this.httpOptions)
                .subscribe(data => {
                observer.next(data);
                return observer.complete();
            }, err => {
                observer.error(err);
                return observer.complete();
            });
        });
    }
    flushAndLogin() {
        localStorage.clear();
        this.nav.navigateRoot('/login');
    }
    /**obtener datos de seguimientos */
    getSeguimiento(patente, token = '') {
        return Observable.create((observer) => {
            this.getPatente().then((r) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                var datos = this.data
                    .set('patente', patente)
                    .set('g_c', token);
                return this.http.post(this.apiUrl + configs.api.getSolicitudes, datos.toString(), this.httpOptions)
                    .pipe(catchError(this.handleError)).subscribe(data => {
                    if (data['error_code'] == 401) {
                        this.flushAndLogin();
                    }
                    observer.next(data);
                    observer.complete();
                }, err => {
                    observer.error(err);
                    observer.complete();
                });
            }));
        });
    }
    getSeguimiento2(patente, contract_id, client_id, token = '') {
        return Observable.create((observer) => {
            this.obtenerRut().then(rut => {
                this.getPatente().then((r) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    var vehiculo = JSON.parse(localStorage.getItem("_cap_vehiculo_seleccionado"));
                    var datos = this.data
                        .set('company_id', vehiculo.CompanyId)
                        .set('patente', patente)
                        .set('rut', rut.rut + rut.dv)
                        .set('contract_id', contract_id)
                        .set('client_id', client_id)
                        .set('g_c', token)
                        .set('token_ias', localStorage.getItem('token_ias'));
                    return this.http.post(this.apiUrl_2 + configs.api_2.getServicios, datos.toString(), this.httpOptions)
                        .pipe(catchError(this.handleError)).subscribe(data => {
                        if (data['error'] == 2) {
                            this.flushAndLogin();
                        }
                        observer.next(data);
                        observer.complete();
                    }, err => {
                        observer.error(err);
                        observer.complete();
                    });
                }));
            });
        });
    }
    // obtiene un login segun datos provenientes de 
    // login.page.ts - login.page.html
    getLogin(rut, dv, gc = '') {
        var datos = this.data
            .set('rut', rut)
            .set('dv', dv)
            .set('g_c', gc)
            .set('device_device', this.device.device)
            .set('device_browser', this.device.browser)
            .set('device_sistema', this.device.os);
        return this.http.post(this.apiUrl + configs.api.obtenerPatentes, datos.toString(), this.httpOptions).pipe(catchError(this.handleError));
    }
    //login middleware 2.0
    getLogin2(rut, gc = '') {
        var datos = this.data
            .set('rut', rut)
            .set('g_c', gc)
            .set('usuario', this._crypto.dec(this.params.uss_))
            .set('password', this._crypto.dec(this.params.pss_))
            .set('device_device', this.device.device)
            .set('device_browser', this.device.browser)
            .set('device_sistema', this.device.os);
        return this.http.post(this.apiUrl_2 + configs.api_2.login, datos.toString(), this.httpOptions).pipe(catchError(this.handleError));
    }
    // sección local storage 
    guardarDato(key, dato) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield Storage.set({
                key: key,
                value: JSON.stringify(dato)
            });
        });
    }
    /** obtiene unicamente la patente. */
    getPatente_data() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((res, rej) => {
                this.obtenerDato('vehiculo_seleccionado').then(r => {
                    return res(r.Patente);
                }).catch(e => {
                    return rej(e);
                });
            });
        });
    }
    getPatente_post() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const patente = yield this.obtenerDato('vehiculo_seleccionado');
            return patente;
        });
    }
    getPatente() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.obtenerDato('vehiculo_seleccionado').then(d => {
                console.log("vehiculo: ", d);
            });
            const patente = yield this.obtenerDato('vehiculo_seleccionado');
            const email = yield this.obtenerDato('email');
            const telefono = yield this.obtenerDato('telefono');
            this.patente = yield patente;
            this.telefono = yield telefono;
            this.email = yield email;
        });
    }
    checkSession() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let rut = yield this.obtenerDato('rut');
            return rut ? true : false;
        });
    }
    setAsistenciaEstado(mode) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            Storage.set({ key: 'asistencia', value: JSON.stringify(mode) });
            return null;
        });
    }
    getAsistenciaEstado() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((res, rej) => {
                Storage.get({ key: 'asistencia' }).then(r => {
                    res(JSON.parse(r.value));
                });
            });
        });
    }
    obtenerRut() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let rut = { rut: '', dv: '' };
            let pivot;
            pivot = yield this.obtenerDato('rut');
            rut.rut = pivot.slice(0, -1);
            rut.dv = pivot.slice(-1);
            return rut;
        });
    }
    obtenerDato(key) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const item = yield Storage.get({ key: key });
            return JSON.parse(item.value);
        });
    }
    limpiarDatos() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            localStorage.clear();
        });
    }
    logOut() {
        this.limpiarDatos();
        this.alerta.showToast("", "Su sesión fue cerrada exitosamente", 3, "", "dark");
        this.nav.navigateRoot(['/login']);
    }
    // get geolocacion 
    getLocacion() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield Geolocation.getCurrentPosition({
                enableHighAccuracy: true
            });
        });
    }
    // mergeDicts
    mergeDict(a, b) {
        return Object.assign({}, a, b);
    }
    // serializar Diccionario a URLPARAM
    serializar(dict = []) {
        var str = [];
        for (let v in dict) {
            str.push(encodeURIComponent(v) + "=" + encodeURIComponent(dict[v]));
        }
        ;
        return str.join("&");
    }
    replaceRange(s, start, end, substitute) {
        return s.substring(0, start) + substitute + s.substring(end);
    }
    ofuscar_patente(patente) {
        let patente_ofuscada;
        patente_ofuscada = this.replaceRange(patente, 1, 5, '****');
        return patente_ofuscada;
    }
    show_version() {
        let version = configs.version;
        if (environment.production == false) {
            version = "dev-";
        }
        version = version + configs.version;
        return version;
    }
    /** Obtener si la fecha es correcta a la revisión tecnica.
     */
    consultar_fecha_patente(fecha) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let mes = fecha.getMonth();
            let patente = yield this.getPatente_data();
            let ultimo_digito = parseInt(patente.substring(6, 5));
            if (ultimo_digito == configs.calendario_patente[mes][1]) {
                // console.log("Mes correcto");
                return true;
            }
            else {
                // console.log("No es tu mes");
                return false;
            }
        });
    }
}
ApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.NavController), i0.ɵɵinject(i3.DomSanitizer), i0.ɵɵinject(i4.AlertasService), i0.ɵɵinject(i5.DeviceDetectorService), i0.ɵɵinject(i6.CryptoService), i0.ɵɵinject(i7.ReCaptchaV3Service)); }, token: ApiService, providedIn: "root" });
